<template>
  <div class="container">
    <div class="bg">
      <div>
        <img :src="require('@/assets/logo.png')" style="width: 95px; height: 64px" />
        <div class="flex-wrap" style="margin-top: 8px">
          <img :src="require('@/assets/main1_phone.png')" style="width: 24px; height: 24px" @click="fnContact" />
          <div class="search flex-con align-start" @click="fnSearch">
            <img :src="require('@/assets/main1_search.png')" style="width: 17px; height: 17px" />
            <div>请输入商品名称搜索</div>
          </div>
          <img :src="require('@/assets/main1_car.png')" style="width: 24px; height: 24px" @click="fnCar" />
        </div>
      </div>
    </div>
    <div class="box">
      <div style="margin-top: -45px">
        <van-swipe class="my-swipe" :autoplay="1000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in msg.bigBanner" :key="item.banner_id">
            <img lazy-load :src="item.banner_image" @click="fnBanner(item.banner_id,item.banner_href,item.banner_content)"
              style=" width: 100%;height: 100%;border-radius: 10px;object-fit: cover;" />
          </van-swipe-item>
        </van-swipe>
      </div>

      <div class="align-around" style="margin: 14px 0 17px">
        <div class="align-center">
          <img :src="require('@/assets/main1_5.png')" style="width: 18px; height: 18px; margin-right: 4px" />
          <div class="color333-12">厂家直供</div>
        </div>
        <div class="align-center">
          <img :src="require('@/assets/main1_6.png')" style="width: 18px; height: 18px; margin-right: 4px" />
          <div class="color333-12">阳光采购</div>
        </div>
        <div class="align-center">
          <img :src="require('@/assets/main1_7.png')" style="width: 18px; height: 18px; margin-right: 4px" />
          <div class="color333-12">价格优惠</div>
        </div>
        <div class="align-center">
          <img :src="require('@/assets/main1_8.png')" style="width: 18px; height: 18px; margin-right: 4px" />
          <div class="color333-12">品质保障</div>
        </div>
      </div>

      <div class="align-between" style="margin-bottom: 9px">
        <div class="column-middle" @click="fnNav(1)">
          <img :src="require('@/assets/main1_1.png')" style="width: 40px; height: 40px; margin-bottom: 5px" />
          <div class="color333-13">精选品类</div>
        </div>
        <div class="column-middle" @click="fnSale(1, '')">
          <img :src="require('@/assets/main1_2.png')" style="width: 40px; height: 40px; margin-bottom: 5px" />
          <div class="color333-13">清仓特卖</div>
        </div>
        <div class="column-middle" @click="fnYx">
          <img :src="require('@/assets/main1_3.png')" style="width: 40px; height: 40px; margin-bottom: 5px" />
          <div class="color333-13">行业优选</div>
        </div>
        <div class="column-middle" @click="fnBrand">
          <img :src="require('@/assets/main1_4.png')" style="width: 40px; height: 40px; margin-bottom: 5px" />
          <div class="color333-13">品牌定制</div>
        </div>
      </div>

      <div class="action">
        <div class="align-between" @click="fnNav(2)">
          <div class="action_btn align-center">#行业动态</div>
          <div class="align-center">
            <div class="coloraaa-12">更多</div>
            <img :src="require('@/assets/jt_r1.png')" style="width: 14px; height: 14px; margin-left: 4px" />
          </div>
        </div>
        <div class="color333-14 ellipsis-2" style="margin-top: 4px" @click="fnXq1(item.info_id)"
          v-for="(item, index) in msg.info">
          {{ item.info_title }}
        </div>
      </div>
    </div>
    <div class="bg_f5"></div>

    <div class="xl_box" v-for="(item, index) in msg.goodsCate">
      <div class="xl_img">
        <img :src="item.gc_image" style="width: 100%; height: 47px;object-fit: cover;" />
        <div class="xl_more align-between">
          <div class="more_left align-center">
            <div class="more_xian"></div>
            <div class="colorfff-14">{{ item.gc_name }}</div>
          </div>
          <div class="more_right align-center" @click="fnSale(2, item.gc_url_one)">
            <div class="color333-12">更多</div>
            <img :src="require('@/assets/jt_r2.png')" style="width: 14px; height: 14px; margin-left: 4px" />
          </div>
        </div>
      </div>
      <div class="goods_box flex-wrap1">
        <div class="goods_item" @click="fnXq(item1.goods_id)" v-for="(item1, index1) in item.goods">
          <img :src="item1.goods_thumb" style="width: 100%" />
          <div class="color333-14 ellipsis-2" style="height: 40px; margin: 10px 0">
            {{ item1.goods_name }}
          </div>
          <div class="color777-12 hidden">已售{{ item1.goods_saled }}</div>
          <div class="align-between" style="margin-top: 4px">
            <div class="color333-12" v-if="Number(item1.goods_floor_price) > 0">
              ￥ <span class="C52B33-18">{{ item1.goods_floor_price }}</span><span class="color777-14" style="text-decoration: line-through;" v-if="Number(item1.goods_old_price) > 0">￥{{ item1.goods_old_price }}</span>
            </div>
            <div class="color333-12" v-else>
              <span class="C52B33-18">面议</span>
            </div>
            <div class="colorfff-14 buy_btn align-center">购买</div>
          </div>
        </div>
      </div>
    </div>


    <NavBar />
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import { Dialog } from 'vant'
import { indexDataForWX, loginAgreement,tdkPages } from '../api'
export default {
  components: { NavBar },
  data() {
    return {
      msg: {},
      tdkTitle: '',
      tdkKeywords: '',
      tdkDescription: '',
    }
  },
  metaInfo() {
    return {
      title: this.tdkTitle,
      meta: [
        { name: 'keywords', content: this.tdkKeywords },
        { name: 'description', content: this.tdkDescription },
      ],
    }
  },
  methods: {
    fnGetTdk(type) {
      let params = {
        type: type,
      }
      tdkPages(params).then((data) => {
        if (data.data.status == 200) {
          this.tdkTitle = data.data.msg.title || '本牛'
          this.tdkKeywords = data.data.msg.keyword || '本牛'
          this.tdkDescription = data.data.msg.desc || '本牛'
        }
      })
    },
    initData() {
      indexDataForWX({

      }).then((data) => {
        if (data.data.status == 200) {
          this.msg = data.data.msg;
          // alert(data.data.msg.bigBanner);
        }
      })
    },
    fnSearch() {
      this.$router.push({ name: 'searchHistory' })
    },
    fnCar() {
      if (!localStorage.getItem('bh5AllUser')) {
        this.$router.push({ name: 'phoneLogin', params: {isSet:2} });
        return;
      }
      this.$router.push({ name: 'car' })
    },
    fnYx() {
      this.$router.push({ name: 'hyyx' })
    },
    fnBrand() {
      this.$router.push({ name: 'brand' })
    },
    fnXq(id) {
      this.$router.push({ name: "goodsDetail", params: { id: id } })
    },
    fnSale(type, one) {
      if (one == '') {
        this.$router.push({ name: "clearSale" })
      } else {
        this.$router.push({
          path: `/${one}`,
        })
      }
    },
    fnBanner(id,href,content) {
      if (href) {
        window.open(href)
      }else{
        if(content){
          this.$router.push({ name: "bannerDetail", params: { id: id } })
        }else{
          return;
        }
      }   
    },
    fnXq1(id) {
      this.$router.push({ name: "zxDetail", params: { id: id } })
    },
    fnNav(index) {
      this.$bus.$emit('changeTab', index)
    },
    fnContact() {
      loginAgreement({
        type: 10
      }).then((data) => {
        if (data.data.status == 200) {
          window.location.href = 'tel://' + data.data.msg.content1
          // Dialog.confirm({
          //   title: '提示',
          //   message: '确定拨打电话：' + data.data.msg.content1+ '吗？',
          //   confirmButtonColor: '#EFC845',
          // })
          //   .then(() => {
          //     // on confirm
          //     window.location.href = 'tel://' + data.data.msg.content1
          //   })
          //   .catch(() => {
          //     // on cancel
          //   })
        }
      })
    },
    isPC() {
      //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
      var info = navigator.userAgent
      //通过正则表达式的test方法判断是否包含“Mobile”字符串
      var isPhone = /mobile/i.test(info)
      if(!isPhone) {
        window.location.href = 'https://www.niutop.com'
      }
    },
    back() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
            //微信环境
            WeixinJSBridge.call('closeWindow');
      }else{
        window.opener = null; window.open(' ','_self');
      }
	  }
  },
  destroyed() {
	window.removeEventListener('popstate', this.back, false); //false阻止默认事件
  },
  mounted() {
    // this.isPC();
    this.fnGetTdk(1)
    this.initData()
    if (window.history && window.history.pushState) {
		// 往历史记录里面添加一条新的当前页面的url
		history.pushState(null, null, document.URL);
		// 给 popstate 绑定一个方法用来监听页面返回
		window.addEventListener('popstate', this.back, false); //false阻止默认事件
	  }
  },
}
</script>

<style scoped>
.goods_item {
  flex: 1;
  margin: 10px 5px 0 5px;
  width: calc((100% - 22px) / 2);
  min-width: calc((100% - 22px) / 2);
  max-width: calc((100% - 22px) / 2);
  padding-bottom: 10px;
}

.goods_box {
  padding: 0 11px;
  background-color: #fff;
}

.buy_btn {
  width: 46px;
  height: 20px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 1px;
}

.more_right {
  width: 50px;
  height: 18px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 1px;
}

.more_xian {
  width: 4px;
  height: 20px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  margin-right: 8px;
}

.xl_more {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0 16px;
  box-sizing: border-box;
}

.xl_img {
  position: relative;
  height: 47px;
}

.action_btn {
  width: 62px;
  height: 20px;
  background: linear-gradient(90deg, #efc845 0%, #dca01e 100%);
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
}

.action {
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  padding: 8px;
}

.my-swipe {
  border-radius: 10px;
}

.my-swipe .van-swipe-item {
  color: #fff;
  height: 200px;
}

.bg {
  box-sizing: border-box;
  width: 100%;
  padding: 14px 16px 60px;
  background: linear-gradient(90deg, #9c9b96 0%, #676664 100%);
}

.search {
  height: 28px;
  background: #eeeeee;
  border-radius: 15px;
  font-size: 13px;
  color: #aaaaaa;
  padding-left: 16px;
  margin: 0 16px;
}

.box {
  padding: 0 16px 8px;
}
</style>
