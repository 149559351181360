import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueClipBoard from 'vue-clipboard2'
Vue.prototype.$bus = new Vue()
import Meta from 'vue-meta'
Vue.use(Meta)
import {
  Tabbar,
  TabbarItem,
  NavBar,
  Image as VanImage,
  Swipe,
  SwipeItem,
  Search,
  Popup,
  Sticky,
  Tab,
  Tabs,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton,
  Stepper,
  Uploader,
  Cascader,
  Switch,
  Toast,
  TreeSelect,
  List,
  Empty,
  PullRefresh,
  Dialog,
  ActionSheet,
  Icon,
  Overlay
} from 'vant'
Vue.use(Overlay)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(VanImage)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Search)
Vue.use(Popup)
Vue.use(Sticky)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(GoodsAction)
Vue.use(GoodsActionButton)
Vue.use(GoodsActionIcon)
Vue.use(Stepper)
Vue.use(Uploader)
Vue.use(Cascader)
Vue.use(Switch)
Vue.use(Toast)
Vue.use(TreeSelect)
Vue.use(List)
Vue.use(Empty)
Vue.use(PullRefresh)
Vue.use(Dialog)
Vue.use(ActionSheet)
Vue.use(VueClipBoard)
Vue.use(Icon)
import 'vant/lib/index.css'
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
