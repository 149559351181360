import Vue from 'vue'
import VueRouter from 'vue-router'
import main from '../views/main.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [
  // {
  //   path: '/',
  //   redirect: '/main',
  //   component: main,
  // },
  {
    path: '/tgYm',
    name: 'tgYm',
    component: () => import('../views/tgYm.vue'),
  },
  {
    path: '/tgBlm',
    name: 'tgBlm',
    component: () => import('../views/tgBlm.vue'),
  },
  {
    path: '/tgGsl',
    name: 'tgGsl',
    component: () => import('../views/tgGsl.vue'),
  },
  {
    path: '/tgJaz',
    name: 'tgJaz',
    component: () => import('../views/tgJaz.vue'),
  },
  {
    path: '/tgJsb',
    name: 'tgJsb',
    component: () => import('../views/tgJsb.vue'),
  },
  {
    path: '/tgXs',
    name: 'tgXs',
    component: () => import('../views/tgXs.vue'),
  },
  {
    path: '/jingbeijituan',
    name: 'jingbeijituan',
    component: () => import('../views/jingbeijituan.vue'),
  },
  {
    path: '/shop',
    name: 'shop',
    component: () => import('../views/shop.vue'),
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('../views/promotion.vue'),
  },
  {
    path: '/:one([\\da-zA-Z]+)/:two([\\da-zA-Z]+)?/:three([\\da-zA-Z]+)?',
    name: 'specialSale',
    component: () => import('../views/specialSale.vue'),
  },
  {
    path: '',
    name: 'main',
    component: main,
  },
  {
    path: '/juhe'+'.html',
    name: 'action',
    component: () => import('../views/action.vue'),
  },
  {
    path: '/sort'+'.html',
    name: 'sort',
    component: () => import('../views/sort.vue'),
  },
  {
    path: '/us'+'.html',
    name: 'user',
    component: () => import('../views/user.vue'),
  },
  {
    path: '/searchhistory'+'.html',
    name: 'searchHistory',
    component: () => import('../views/searchHistory.vue'),
  },
  {
    path: '/car'+'.html',
    name: 'car',
    component: () => import('../views/car.vue'),
    
  },
  {
    path: '/sureorder/:goods_id/:type/:nums/:attr_id'+'.html',
    name: 'sureOrder',
    component: () => import('../views/sureOrder.vue'),
    meta: {
      keepAlive: true,
      isBack: false,
    },
  },
  {
    path: '/sureordercar/:type/:ids'+'.html',
    name: 'sureOrderCar',
    component: () => import('../views/sureOrderCar.vue'),
    meta: {
      keepAlive: true,
      isBack: false,
    },
  },
  {
    path: '/searchresult'+'.html',
    name: 'searchResult',
    component: () => import('../views/searchResult.vue'),
  },
  {
    path: '/pay/:id/:price/:number'+'.html',
    name: 'pay',
    component: () => import('../views/pay.vue'),
  },
  {
    path: '/market'+'.html',
    name: 'hyyx',
    component: () => import('../views/hyyx.vue'),
  },
  {
    path: '/market/:id'+'.html',
    name: 'hyyx1',
    component: () => import('../views/hyyx1.vue'),
  },
  {
    path: '/oem'+'.html',
    name: 'brand',
    component: () => import('../views/brand.vue'),
  },
  {
    path: '/gongying/:id'+'.html',
    name: 'goodsDetail',
    component: () => import('../views/goodsDetail.vue'),
  },
  {
    path: '/sale'+'.html',
    name: 'clearSale',
    component: () => import('../views/clearSale.vue'),
  },
  {
    path: '/sale/:one?/:two?/:three?',
    name: 'clearSale',
    component: () => import('../views/clearSale.vue'),
  },
  {
    path: '/order/:type?'+'.html',
    name: 'order',
    component: () => import('../views/order.vue'),
  },
  {
    path: '/my/:user_type?'+'.html',
    name: 'my',
    component: () => import('../views/my.vue'),
  },
  {
    path: '/renzheng/:status?'+'.html',
    name: 'renzheng',
    component: () => import('../views/renzheng.vue'),
  },
  {
    path: '/lookWl/:id?'+'.html',
    name: 'lookWl',
    component: () => import('../views/lookWl.vue'),
  },
  {
    path: '/aftersale/:id/:order_number'+'.html',
    name: 'afterSale',
    component: () => import('../views/afterSale.vue'),
  },
  {
    path: '/myaction'+'.html',
    name: 'myAction',
    component: () => import('../views/myAction.vue'),
  },
  {
    path: '/mycollect'+'.html',
    name: 'myCollect',
    component: () => import('../views/myCollect.vue'),
  },
  {
    path: '/mybrowse'+'.html',
    name: 'myBrowse',
    component: () => import('../views/myBrowse.vue'),
  },
  {
    path: '/myaddress/:type?'+'.html',
    name: 'myAddress',
    component: () => import('../views/myAddress.vue'),
  },
  {
    path: '/addaddress/:address_id'+'.html',
    name: 'addAddress',
    component: () => import('../views/addAddress.vue'),
  },
  {
    path: '/distributioncenter'+'.html',
    name: 'distributionCenter',
    component: () => import('../views/distributionCenter.vue'),
  },
  {
    path: '/invoice/:type?'+'.html',
    name: 'invoice',
    component: () => import('../views/invoice.vue'),
  },
  {
    path: '/addinvoice/:fp_id'+'.html',
    name: 'addInvoice',
    component: () => import('../views/addInvoice.vue'),
  },
  {
    path: '/logon/:isSet?'+'.html',
    name: 'phoneLogin',
    component: () => import('../views/phoneLogin.vue'),
  },
  {
    path: '/login/:isSet?'+'.html',
    name: 'pwdLogin',
    component: () => import('../views/pwdLogin.vue'),
  },
  {
    path: '/forgetpwd'+'.html',
    name: 'forgetPwd',
    component: () => import('../views/forgetPwd.vue'),
  },
  {
    path: '/register'+'.html',
    name: 'register',
    component: () => import('../views/register.vue'),
  },
  {
    path: '/orderxq/:id'+'.html',
    name: 'orderXq',
    component: () => import('../views/orderXq.vue'),
  },
  {
    path: '/set'+'.html',
    name: 'set',
    component: () => import('../views/set.vue'),
  },
  {
    path: '/changephone1/:user_phone'+'.html',
    name: 'changePhone1',
    component: () => import('../views/changePhone1.vue'),
  },
  {
    path: '/changephone2'+'.html',
    name: 'changePhone2',
    component: () => import('../views/changePhone2.vue'),
  },
  {
    path: '/setpwd'+'.html',
    name: 'setPwd',
    component: () => import('../views/setPwd.vue'),
  },
  {
    path: '/forgetpwd1/:phone'+'.html',
    name: 'forgetPwd1',
    component: () => import('../views/forgetPwd1.vue'),
  },
  {
    path: '/news/:id'+'.html',
    name: 'zxDetail',
    component: () => import('../views/zxDetail.vue'),
  },
  {
    path: '/activity/:id'+'.html',
    name: 'activityDetail',
    component: () => import('../views/activityDetail.vue'),
  },
  {
    path: '/message'+'.html',
    name: 'message',
    component: () => import('../views/message.vue'),
  },
  {
    path: '/messagedetail/:id'+'.html',
    name: 'messageDetail',
    component: () => import('../views/messageDetail.vue'),
  },
  {
    path: '/about'+'.html',
    name: 'about',
    component: () => import('../views/about.vue'),
  },
  {
    path: '/aboutdetail/:id'+'.html',
    name: 'aboutDetail',
    component: () => import('../views/aboutDetail.vue'),
  },
  {
    path: '/agreement/:type?'+'.html',
    name: 'agreement',
    component: () => import('../views/agreement.vue'),
  },
  {
    path: '/bannerdetail/:id'+'.html',
    name: 'bannerDetail',
    component: () => import('../views/bannerDetail.vue'),
  },
  {
    path: '/wxorderlogin'+'.html',
    name: 'wxOrderLogin',
    component: () => import('../views/wxOrderLogin.vue'),
  },
  {
    path: '/wxlogin'+'.html',
    name: 'wxLogin',
    component: () => import('../views/wxLogin.vue'),
  },
  {
    path: '/contract/:id'+'.html',
    name: 'contract',
    component: () => import('../views/contract.vue'),
  },
  {
    path: '/shopMain'+'.html',
    name: 'shopMain',
    component: () => import('../views/shopMain.vue'),
  },
  {
    path: '/offline/:number:price'+'.html',
    name: 'offline',
    component: () => import('../views/offline.vue'),
  },
  {
    path: '/setEmail'+'.html',
    name: 'setEmail',
    component: () => import('../views/setEmail.vue'),
  },
  {
    path: '/disagreement'+'.html',
    name: 'disagreement',
    component: () => import('../views/disagreement.vue'),
  },
  {
    path: '/apporder/:id'+'.html',
    name: 'apporder',
    component: () => import('../views/apporder.vue'),
  },
] 
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
