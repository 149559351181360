<template>
  <van-tabbar
    v-model="active"
    active-color="#DCA01E"
    inactive-color="#777"
    fixed
  >
    <van-tabbar-item
      :to="item.name"
      @click="tabIndex(index)"
      v-for="(item, index) in tabbars"
      :key="'tabbar' + index"
    >
      <span>{{ item.title }}</span>
      <template #icon="props">
        <img :src="props.active ? item.active : item.normal" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'indexTab',
  data() {
    return {
      active: 0, //默认选中tab
      tabbars: [
        {
          name: 'main',
          title: '首页',
          normal: require('@/assets/nav11.png'),
          active: require('@/assets/nav1.png'),
        },
        {
          name: 'sort',
          title: '分类',
          normal: require('@/assets/nav22.png'),
          active: require('@/assets/nav2.png'),
        },
        {
          name: 'action',
          title: '动态',
          normal: require('@/assets/nav33.png'),
          active: require('@/assets/nav3.png'),
        },
        {
          name: 'user',
          title: '我的',
          normal: require('@/assets/nav44.png'),
          active: require('@/assets/nav4.png'),
        },
      ],
    }
  },
  mounted() {
    this.$bus.$on('changeTab', (val) => {
      sessionStorage.setItem('tabIndex', val)
      let index = sessionStorage.getItem('tabIndex')
      if (index) {
        this.tabIndex(index)
      }
    })
    let index = sessionStorage.getItem('tabIndex')
    if (index) {
      this.tabIndex(index)
    }
  },
  methods: {
    tabIndex(index) {
      index = Number(index)
      this.active = index
      // 记录当前tab页
      sessionStorage.setItem('tabIndex', index)
      let path = this.tabbars[index].name
      // alert(path);
      this.$router.push({ name: path })
    },
  },
}
</script>

<style>
.van-tabbar {
  box-shadow: 0px 0px 3px 0px #f5f5f5;
}
.van-tabbar-item__icon img {
  width: 24px;
  min-height: 24px;
}
</style>
