<template>
  <div id="draggg" @click="fnSelect" @touchstart="down($event)" @touchmove="move($event)" @touchend="end($event)"
    :style="{ left: x, right: y }" class="aaa">
    <img :src="require('@/assets/kf.png')" class="kefu_icon">
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      flag: false,
      cur: {
        x: 0,
        y: 0
      },
      nx: 0,
      ny: 0,
      dx: 0,
      dy: 0,
      x: window.innerWidth,
      y: 0,
      div2: '',
      Loop: 0
    }
  },
  mounted() {
    // this.drag();
  },
  methods: {
    fnSelect() {
      if(localStorage.getItem('bh5AllUser')){
      var nonce = Date.parse(new Date());
      var timestamp = Date.parse(new Date());
      var web_token = JSON.parse(localStorage.getItem('bh5AllUser')).user_phone;
      var sign_str = 'nonce=' + nonce + '&timestamp=' + timestamp + '&web_token=' + web_token + '&f4e02400be01856212d1b465688490f0';
      sign_str = this.sha1(sign_str);
      sign_str = sign_str.toUpperCase();
      window.location.href = 'https://1800498.s4.udesk.cn/im_client/?web_plugin_id=63975' + '&c_name=' + JSON.parse(localStorage.getItem('bh5AllUser')).user_phone + '&c_phone=' + JSON.parse(localStorage.getItem('bh5AllUser')).user_phone + '&nonce=' + nonce + '&signature=' + sign_str + '&timestamp=' + timestamp + '&web_token=' + web_token;
      }else{
        window.location.href = 'https://1800498.s4.udesk.cn/im_client/?web_plugin_id=63975';
      }  
    },
    down(event) {
      event.preventDefault();
      event.stopPropagation();
      this.flag = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.cur.x = touch.clientX;
      this.cur.y = touch.clientY;
      this.dx = document.getElementById('draggg').offsetLeft;
      this.dy = document.getElementById('draggg').offsetTop;
      var _this = this;
      this.Loop = setTimeout(function () {
        _this.Loop = 0;
      }, 500);
      return false;
    },
    move(event) {
      if (this.flag) {
        var touch;
        event.preventDefault();
        event.stopPropagation();
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.cur.x;
        this.ny = touch.clientY - this.cur.y;
        this.x = this.dx + this.nx;
        this.y = this.dy + this.ny;
        // console.log(this.x+'|||'+this.y);
        if (this.x < 0) {
          this.x = 10;
        }
        if (this.y < 0) {
          this.y = 10;
        }
        if (this.x > window.innerWidth - 65) {
          this.x = window.innerWidth - 65;
        }
        if (this.y > window.innerHeight - 70) {
          this.y = window.innerHeight - 70;
        }
        document.getElementById('draggg').style.left = this.x + "px";
        document.getElementById('draggg').style.top = this.y + "px";
      }
    },
    //鼠标释放时候的函数
    end(event) {
      var _this = this;
      clearTimeout(_this.Loop);
      if (_this.Loop !== 0) {
        this.fnSelect()
      }
      return false;
      this.flag = false;
    },
    encodeUTF8(s) {
      var i, r = [], c, x;
      for (i = 0; i < s.length; i++)
        if ((c = s.charCodeAt(i)) < 0x80) r.push(c);
        else if (c < 0x800) r.push(0xC0 + (c >> 6 & 0x1F), 0x80 + (c & 0x3F));
        else {
          if ((x = c ^ 0xD800) >> 10 == 0) //对四字节UTF-16转换为Unicode
            c = (x << 10) + (s.charCodeAt(++i) ^ 0xDC00) + 0x10000,
              r.push(0xF0 + (c >> 18 & 0x7), 0x80 + (c >> 12 & 0x3F));
          else r.push(0xE0 + (c >> 12 & 0xF));
          r.push(0x80 + (c >> 6 & 0x3F), 0x80 + (c & 0x3F));
        };
      return r;
    },
    sha1(s) {
      var data = new Uint8Array(this.encodeUTF8(s))
      var i, j, t;
      var l = ((data.length + 8) >>> 6 << 4) + 16, s = new Uint8Array(l << 2);
      s.set(new Uint8Array(data.buffer)), s = new Uint32Array(s.buffer);
      for (t = new DataView(s.buffer), i = 0; i < l; i++)s[i] = t.getUint32(i << 2);
      s[data.length >> 2] |= 0x80 << (24 - (data.length & 3) * 8);
      s[l - 1] = data.length << 3;
      var w = [], f = [
        function () { return m[1] & m[2] | ~m[1] & m[3]; },
        function () { return m[1] ^ m[2] ^ m[3]; },
        function () { return m[1] & m[2] | m[1] & m[3] | m[2] & m[3]; },
        function () { return m[1] ^ m[2] ^ m[3]; }
      ], rol = function (n, c) { return n << c | n >>> (32 - c); },
        k = [1518500249, 1859775393, -1894007588, -899497514],
        m = [1732584193, -271733879, null, null, -1009589776];
      m[2] = ~m[0], m[3] = ~m[1];
      for (i = 0; i < s.length; i += 16) {
        var o = m.slice(0);
        for (j = 0; j < 80; j++)
          w[j] = j < 16 ? s[i + j] : rol(w[j - 3] ^ w[j - 8] ^ w[j - 14] ^ w[j - 16], 1),
            t = rol(m[0], 5) + f[j / 20 | 0]() + m[4] + w[j] + k[j / 20 | 0] | 0,
            m[1] = rol(m[1], 30), m.pop(), m.unshift(t);
        for (j = 0; j < 5; j++)m[j] = m[j] + o[j] | 0;
      };
      t = new DataView(new Uint32Array(m).buffer);
      for (var i = 0; i < 5; i++)m[i] = t.getUint32(i << 2);

      var hex = Array.prototype.map.call(new Uint8Array(new Uint32Array(m).buffer), function (e) {
        return (e < 16 ? "0" : "") + e.toString(16);
      }).join("");

      return hex;
    }

  },
}
</script>
  
<style>
.aaa {
  width: 54px;
  height: 54px;
  position: fixed;
  right: 10px;
  top: 73vh;
  z-index: 99;
}

.kefu_icon {
  width: 54px;
  height: 54px;
  /* position: fixed;
  right: 10px;
  top: 73vh;
  z-index:99; */
}
</style>
  