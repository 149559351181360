<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <Kefu v-show="routerUrl != 'phoneLogin' && routerUrl != 'pwdLogin' && routerUrl != 'promotion'" />
  </div>
</template>
<script>
import Kefu from '@/components/Kefu.vue'
export default {
  name: 'indexTab',
  components: {
    Kefu,
  },
  data() {
    return {
      routerUrl: '',
    }
  },
  mounted() {
    this.isPC();
  },
  beforeUpdate() {
    this.routerUrl = this.$route.name
    console.log(this.routerUrl);
  },
  methods: {
    isPC() {
      //   /market/6.html   location.pathname
      setTimeout(() => {
        console.log('///'+this.$route.name);
        let currentPath = location.pathname;
        console.log(currentPath);
        var info = navigator.userAgent
        var isPhone = /mobile/i.test(info)
        if(!isPhone) {
          if(this.$route.name=='specialSale' || this.$route.name=='action' || this.$route.name=='zxDetail' || this.$route.name=='activityDetail' || this.$route.name=='goodsDetail' || this.$route.name=='clearSale' || this.$route.name=='hyyx' || this.$route.name=='hyyx1' || this.$route.name=='brand'){
            window.location.href = 'https://www.niutop.com'+location.pathname;
          }else if(this.$route.name=='shop'){
            window.location.href = 'https://www.niutop.com'+location.pathname+'?store_id='+this.$route.query.store_id;
          }else if(this.$route.name=='jingbeijituan'){
            window.location.href = 'https://www.niutop.com'+location.pathname;
          }else{
            window.location.href = 'https://www.niutop.com'
          }   
        }
      }, 200);  
    },
  }
  // watch: {
  //     '$route': { // $route可以用引号，也可以不用引号  监听的对象
  //       handler(to, from){
  //         console.log('路由变化了')
  //         // alert('当前页面路由：' + JSON.stringify(to));
  //         this.$toast(JSON.stringify(to))
  //         console.log(JSON.stringify(to))
  //         console.log('上一个路由：' + JSON.stringify(from));
  //       },
  //       deep: true, // 深度观察监听 设置为 true
  //       immediate: true, // 第一次初始化渲染就可以监听
  //   }
  // }
}

</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #2c3e50; */
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>

