import http from '../utils/request'

// export const HOST_Url = 'https://niutop.xuanwumobile.com/mobile'
export const HOST_Url = 'https://m.niutop.com'

export const getSomething = (data) => {
  //推广页提交表单
  return http.post('api/Home/getSomething', data)
}
export const getSomethingDatas = (data) => {
  //推广页轮播
  return http.post('api/Home/getSomethingDatas', data)
}
export const sendCode = (data) => {
  //获取验证码,type    1 其它   2 注册   3 登录
  return http.post('api/Login/sendCode', data)
}
export const pcRegister = (data) => {
  //注册
  return http.post('api/Login/pcRegister', data)
}
export const pcCodeLogin = (data) => {
  //验证码登录
  return http.post('api/Login/codeLogin', data)
}
export const passwordLogin = (data) => {
  //手机号密码登录
  return http.post('api/Login/passwordLogin', data)
}
export const indexDataForWX = (data) => {
  //首页
  return http.post('api/Home/indexDataForWX', data)
}
export const secondAndThirdCate = (data) => {
  //二级、三级分类
  return http.post('api/Home/secondAndThirdCate', data)
}
export const goodsList = (data) => {
  //产品列表
  return http.post('api/Home/goodsList', data)
}
export const selectGoodsCate = (data) => {
  //点击二级获取三级id
  return http.post('api/Home/selectGoodsCate', data)
}
export const goodsTag = (data) => {
  //筛选的标签
  return http.post('api/Home/goodsTag', data)
}
export const searchHistoryList = (data) => {
  //搜索历史
  return http.post('api/Home/searchHistoryList', data)
}
export const createSearchHistory = (data) => {
  //创建搜索历史
  return http.post('api/Home/createSearchHistory', data)
}
export const delHistory = (data) => {
  //删除搜索
  return http.post('api/Home/delHistory', data)
}
export const hotSearch = (data) => {
  //热门搜索
  return http.post('api/Home/hotSearch', data)
}
export const getGoodsCateInfo = (data) => {
  //点击三级,获取所属的二级
  return http.post('api/Home/getGoodsCateInfo', data)
}
export const goodsCate = (data) => {
  //根据一级获取二级三级
  return http.post('api/Home/goodsCate', data)
}
export const goodsDetail = (data) => {
  //产品详情
  return http.post('api/Home/goodsDetail', data)
}
export const doCollect = (data) => {
  //收藏/取消收藏
  return http.post('api/Home/doCollect', data)
}
export const insertLookHistory = (data) => {
  //插入浏览历史
  return http.post('api/Home/insertLookHistory', data)
}
export const getAttrInfo = (data) => {
  //根据规格获取价格
  return http.post('api/Selfcar/getAttrInfo', data)
}
export const putGoodsIncar = (data) => {
  //加入购物车
  return http.post('api/Selfcar/putGoodsIncar', data)
}
export const mycar = (data) => {
  //我的购物车
  return http.post('api/Selfcar/mycar', data)
}
export const carUpDown = (data) => {
  //购物车——加减
  return http.post('api/Selfcar/carUpDown', data)
}
export const carSetNum = (data) => {
  //购物车——设置值
  return http.post('api/Selfcar/carSetNum', data)
}
export const carDel = (data) => {
  //购物车——删除
  return http.post('api/Selfcar/carDel', data)
}
export const sureOrder = (data) => {
  //立即购买商品--看详情
  return http.post('api/Selfcar/sureOrder', data)
}
export const sureOrderCar = (data) => {
  //购物车购买商品--看详情
  return http.post('api/Selfcar/sureOrderCar', data)
}
export const myAddress = (data) => {
  //我的地址列表
  return http.post('api/My/myAddress', data)
}
export const getArea = (data) => {
  //获取省市区
  return http.post('api/Base/getArea', data)
}
export const myAddressAdd = (data) => {
  //新增地址
  return http.post('api/My/myAddressAdd', data)
}
export const getOneAddress = (data) => {
  //地址详情
  return http.post('api/My/getOneAddress', data)
}
export const myAddressEdit = (data) => {
  //编辑地址
  return http.post('api/My/myAddressEdit', data)
}
export const setDefaultAddress = (data) => {
  //列表页设置默认
  return http.post('api/My/setDefaultAddress', data)
}
export const insertFaPiao = (data) => {
  //新增发票
  return http.post('api/Home/insertFaPiao', data)
}
export const faPiaoList = (data) => {
  //我的发票列表
  return http.post('api/Home/faPiaoList', data)
}
export const showFaPiao = (data) => {
  //展示发票详情
  return http.post('api/Home/showFaPiao', data)
}
export const faPiaoUpdate = (data) => {
  //编辑发票
  return http.post('api/Home/faPiaoUpdate', data)
}
export const payOrder = (data) => {
  //立即支付
  return http.post('api/Selfcar/payOrder', data)
}
export const myOrders = (data) => {
  //我的订单
  return http.post('api/Selforder/myOrders', data)
}
export const myOrderInfo = (data) => {
  //我的某一个订单详情
  return http.post('api/Selforder/myOrderInfo', data)
}
export const cancleOrder = (data) => {
  //手动取消订单
  return http.post('api/Selforder/cancleOrder', data)
}
export const receiptMyOrder = (data) => {
  //确认收货
  return http.post('api/Selforder/receiptMyOrder', data)
}
export const getWlInfo = (data) => {
  //查看物流详情
  return http.post('api/Selforder/getWlInfo', data)
}
export const afterSaleOrder = (data) => {
  //申请售后
  return http.post('api/Selforder/afterSaleOrder', data)
}
export const afterSaleCate = (data) => {
  //售后类型
  return http.post('api/Home/afterSaleCate', data)
}
export const showUserBaseForH5 = (data) => {
  //用户基础信息展示
  return http.post('api/Home/showUserBaseForH5', data)
}
export const authStatus = (data) => {
  //企业认证状态
  return http.post('api/Home/authStatus', data)
}
export const getPost = (data) => {
  //岗位列表
  return http.post('api/Home/getPost', data)
}
export const industryCate = (data) => {
  //行业类别
  return http.post('api/Home/industryCate', data)
}
export const areaList = (data) => {
  //省市列表
  return http.post('api/Home/areaList', data)
}
export const updateUserBase = (data) => {
  //更新用户基本信息
  return http.post('api/Home/updateUserBase', data)
}
export const showUserBase = (data) => {
  //用户基础信息展示
  return http.post('api/Home/showUserBase', data)
}
export const commitApply = (data) => {
  //提交企业认证
  return http.post('api/Home/commitApply', data)
}
export const showAuth = (data) => {
  //企业认证信息展示
  return http.post('api/Home/showAuth', data)
}
export const checkOldPhone = (data) => {
  //验证旧手机号
  return http.post('api/Home/checkOldPhone', data)
}
export const bandNewPhone = (data) => {
  //绑定新手机号
  return http.post('api/Home/bandNewPhone', data)
}
export const pcForgetPasswordFirstStep = (data) => {
  //忘记密码第一步
  return http.post('api/Login/forgetPasswordFirstStep', data)
}
export const pcForgetPasswordSecondStep = (data) => {
  //忘记密码第二步
  return http.post('api/Login/forgetPasswordSecondStep', data)
}
export const appList = (data) => {
  //行业优选
  return http.post('api/Apps/appList', data)
}
export const appDetail = (data) => {
  //行业优选详情
  return http.post('api/Apps/appDetail', data)
}
export const loginAgreement = (data) => {
  //配置
  return http.post('api/Foot/loginAgreement', data)
}
export const appOrder = (data) => {
  //行业优选定制
  return http.post('api/Apps/appOrder', data)
}
export const brandOrder = (data) => {
  //品牌订制
  return http.post('api/Home/brandOrder', data)
}
export const informationList = (data) => {
  //资讯分类
  return http.post('api/Home/informationList', data)
}
export const oneInfoList = (data) => {
  //资讯列表
  return http.post('api/Home/oneInfoList', data)
}
export const infoDetail = (data) => {
  //资讯详情
  return http.post('api/Home/infoDetail', data)
}
export const allActivityList = (data) => {
  //所有活动列表
  return http.post('api/Home/allActivityList', data)
}
export const activityDetail = (data) => {
  //活动详情
  return http.post('api/Home/activityDetail', data)
}
export const applyActivity = (data) => {
  //报名活动
  return http.post('api/Home/applyActivity', data)
}
export const myActivity = (data) => {
  //我的活动
  return http.post('api/Home/myActivity', data)
}
export const myCollect = (data) => {
  //我的收藏
  return http.post('api/Home/myCollect', data)
}
export const myLookHistory = (data) => {
  //我的浏览历史
  return http.post('api/Home/myLookHistory', data)
}
export const tiChengList = (data) => {
  //分销中心
  return http.post('api/Home/tiChengList', data)
}
export const myLower = (data) => {
  //下级用户
  return http.post('api/Home/myLower', data)
}
export const noticeList = (data) => {
  //消息列表
  return http.post('api/Home/noticeList', data)
}
export const noticeDetail = (data) => {
  //消息详情
  return http.post('api/Home/noticeDetail', data)
}
export const bannerDetail = (data) => {
  //轮播图详情
  return http.post('api/Home/bannerDetail', data)
}
export const servicePolicy = (data) => {
  //服务政策
  return http.post('api/Foot/servicePolicy', data)
}
export const policyDetail = (data) => {
  //服务政策详情authLoginWxH5
  return http.post('api/Foot/policyDetail', data)
}
export const uploadFile = (data) => {
  //上传文件
  return http.post('api/Base/uploadFile', data)
}
export const authLoginWxH5 = (data) => {
  //授权登录
  return http.post('api/Base/authLoginWxH5', data)
}
export const qianshu = (data) => {
  //获取签署合同链接
  return http.post('api/Selforder/qianshu', data)
}
export const getContactDownurl = (data) => {
  //获取合同下载链接
  return http.post('api/Selforder/getContactDownurl', data)
}
export const storeInfo = (data) => {
  //店铺基本信息
  return http.post('api/Store/storeInfo', data)
}
export const onlyPay = (data) => {
  //单独为未支付订单做的支付
  return http.post('api/Selfcar/onlyPay', data)
}
export const getCateInfoByUrl = (data) => {
  //获取分类id
  return http.post('api/Home/getCateInfoByUrl', data)
}
export const tdkPages = (data) => {
  //获取tdk
  return http.post('api/Home/tdkPages', data)
}
export const fxxy = (data) => {
  //分销协议
  return http.post('api/Home/fxxy', data)
}