import axios from 'axios'

const http = axios.create({
  // baseURL: 'http://niu.xuanwumobile.com/',
  // baseURL: 'http://niutop.xuanwumobile.com/',
  baseURL: 'https://service.niutop.com/',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
})
// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    if(localStorage.getItem('bh5AllUser')){
      // config.data.user_id=10;
      // alert(JSON.parse(localStorage.getItem('bh5AllUser')).user_id);
      config.data.user_id=localStorage.getItem('bh5AllUser')?JSON.parse(localStorage.getItem('bh5AllUser')).user_id:'0';
    }else {
      config.data.user_id = 0
    }
    console.warn('入参' + JSON.stringify(config))
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    console.warn('返回参:' + JSON.stringify(response))
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.status != 200 && response.config.url!='api/Home/authStatus') {
    alert(response.data.msg)
    }
    return response
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.warn(JSON.stringify(error))
    return Promise.reject(error)
  }
)

export default http